<template>
  <van-nav-bar :title="receiveAddress.name" left-arrow @click-left="goBack" :border="false" />
  <div class="seller-account-form">
    <div class="form-wrap">
      <div class="form-title">{{$t('field.account')}}</div>
      <van-field
        v-model="form.sellerAccount"
        class="form-field"
        size="large"
        maxlength="32"
        clearable
        :placeholder="$t('placeholder.default')"
      />
      <template v-if="receiveAddress.model === 2">
        <div class="form-title">{{$t('field.realname')}}</div>
        <van-field
          v-model="form.realName"
          class="form-field"
          size="large"
          maxlength="32"
          clearable
          :placeholder="$t('placeholder.default')"
        />
      </template>
      <div class="form-title">{{$t('field.address')}}</div>
      <div class="field-wrap">
        <van-field
          v-model="form.qrCode"
          class="form-field with-suffix"
          size="large"
          maxlength="64"
          clearable
          :placeholder="$t('placeholder.default')"
        />
        <div class="suffix-wrap" @click="goToQrcode">
          <van-icon name="scan" size="24" />
        </div>
      </div>
      <template v-if="id">
        <div class="form-title">{{$t('field.failMsg')}}</div>
        <van-field
          class="form-field readonly"
          :model-value="failMsg"
          readonly
        />
      </template>
    </div>
    <van-button
      size="large"
      type="primary"
      block
      @click="handleAddAccount"
      :disabled="confirmDisabled"
    >{{$t('button.submit')}}</van-button>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import i18n from '@/assets/lang/index';
import { addSellerAccount, modifySellerAccount } from "@/api";

export default {
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
    return {
      goBack,
    };
  },
  
  data() {
    return {
      receiveAddress: JSON.parse(localStorage.getItem('receiveAddress')),
      id: '',
      form: {
        sellerAccount: '',
        realName: '',
        qrCode: '',
        failMsg: '',
        ...this.$AppData.dataStore,
      }
    };
  },
  
  computed: {
    confirmDisabled() {
      if (this.receiveAddress.model === 2) {
        return !this.form.sellerAccount || !this.form.realName || !this.form.qrCode;
      } else {
        return !this.form.sellerAccount || !this.form.qrCode;
      }
      
    },
  },
  
  mounted() {
    this.$AppData.clearDataStore();
  },
  
  methods: {
    handleAddAccount() {
      const doPost = this.id ? modifySellerAccount : addSellerAccount;
      doPost({
        id: this.id,
        type: this.receiveAddress.type,
        ...this.form,
      }).then(res => {
        this.$toast({ type: 'success', message: i18n.global.t('notify.operateSuccess') });
        this.$router.back();
      });
    },
    goToQrcode() {
      this.$AppData.saveDataStore(this.form);
      this.$router.push('/qrcode');
    },
  },
};
</script>

<style lang="less">
.seller-account-form {
  padding: 12px;
  padding-bottom: 50px;
  .form-wrap {
    padding: 0 12px 16px;
    margin-bottom: 12px;
    background: #fff;
    border-radius: 2px;
    overflow: hidden;
    .form-title {
      margin: 16px 0;
      font-size: 14px;
      color: #28293D;
      font-weight: 400;
    }
    .field-wrap {
      position: relative;
      .suffix-wrap {
        position: absolute;
        top: 0;
        right: 12px;
        width: 30px;
        line-height: 46px;
        text-align: right;
        font-weight: 400;
        color: #6F6F93;
        i, span {
          font-size: 16px;
          vertical-align: middle;
        }
        .triangle {
          width: 8px;
          margin-left: 12px; 
          vertical-align: middle;
        }
      }
    }
    .form-field {
      font-size: 16px;
      font-weight: 500;
      color: #000;
      background: #F9FAFB;
      border-radius: 4px;
      &.with-suffix {
        padding-right: 40px;
      }
      &.readonly {
        background: transparent;
      }
    }
    .field-extra {
      padding: 8px 0;
      font-size: 10px;
      color: #6F6F93;
      line-height: 12px;
      font-weight: 400;
      .highlight {
        color: var(--van-primary-color);
      }
    }
  }
}
</style>
